main {
  margin: 0 auto;
  max-width: 90%;
  padding: 50px 30px;
}

:root {
  --primary: #633594;
  --secondary: #8B8982;
  --tertiary: #4392f1;
  --light: #FFFFFF;
  --dark: #000000;
  --darker: #111111;
  --error: rgb(228, 46, 1);
  --spacing-one: calc(1rem * 0.5);
  --spacing-two: calc(1rem);
  --spacing-five: calc(2.5rem);
}

* {
  box-sizing: border-box;
}

html,
body {
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-weight: 600;
  line-height: 1.5;
  font-size: 18px;
  letter-spacing: .1em;
  background: #FFFFFF;
  color: var(--dark);
  font-family: 'Georgia', cursive;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Georgia', sans-serif;
  font-weight: 500;
  margin: 5px 0;
  line-height: 1.25;
}

h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  font-size: 1.1rem;
  margin-top: 0;
}

a {
  color: var(--tertiary);
  text-decoration: none;
  font-weight: bold;
}

a:hover {
  text-decoration: underline;
}

header {
  background: var(--dark);
}

header a {
  color: var(--light);
}

button {
  font-size: 1rem;
  text-decoration: none;
  padding: 0.25rem 1rem;
  border-radius: .5rem;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 0 1px;
  background-color: var(--secondary);
}

button:hover {
  opacity: .8;
}

button[disabled] {
  cursor: default;
  opacity: .8;
}

img {
  max-width: 100%;
}

span[role="img"] {
  cursor: default;
}

form {
  width: 40%;
}

input {
  font-size: 1rem;
  padding: 0.25rem;
  border: 1px solid var(--dark);
  outline: none;
  border-radius: 3px;
  transition: box-shadow 0.2s;
}

input:focus {
  border: 1px solid var(--secondary);
  box-shadow: 0 0 .25rem rgba(0, 0, 0, .5);
}

li {
  list-style: none;
}

nav a:hover {
  text-decoration: none;
}

nav li {
  cursor: pointer;
}

textarea {
  width: 600px;
  height: 120px;
  border: 3px solid #cccccc;
  padding: 5px;
}

hr {
  background-color: var(--secondary);
  height: 8px;
  width: 100%;
}

.success hr {
  background-color: var(--secondary);
  height: 3px;
  width: 100%;
}

.footer {
  background-color: lightgrey;
  padding: 0.5rem 0;
}

.footer hr {
  height: 1px;
}

.footer-content {
  text-align: center;
}

.lower-footer {
  font-size: 10px;
}

.footer-address p {
  font-size: 12px;
}

.footer-links ul {
  padding-top: 10px;
  font-size: 12px;
}

.footer-links p {
  padding-top: 10px;
  font-size: 12px;
}

.social-icons {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.social-icons a {
  color: white;
  font-size: 1.5rem;
  margin: 0 1rem;
}

#about h1 {
  font-size: 3.5rem;
  font-weight: bold;
}

#about-image {
  padding-top: 150px;
  padding-right: 20px;
}

.carousel-container {
  width: 100%;
  max-width: 100%;
  overflow: visible;
}

.custom-dropdown-menu {
  width: 200px; /* Set the desired width here */
  color: #000000;
}

.cookie-consent-overlay {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
}

.cookie-consent {
  position: relative;
  width: 90%;
  max-width: 1980px;
  margin: 10px auto;
  padding: 10px;
  background-color: #fff;
  color: #000;
  text-align: center;
  border-radius: 4px;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
}

.space-between {
  justify-content: space-between;
}

.flex-end {
  justify-content: flex-end;
}

.mx-1 {
  margin-right: var(--spacing-one);
  margin-left: var(--spacing-one);
}

.mx-2 {
  margin-right: var(--spacing-two);
  margin-left: var(--spacing-two);
}

.my-1 {
  margin-top: var(--spacing-one);
  margin-bottom: var(--spacing-one);
}

.my-2 {
  margin-top: var(--spacing-two);
  margin-bottom: var(--spacing-two);
}

.my-5 {
  margin-top: var(--spacing-five);
  margin-bottom: var(--spacing-five);
}

.px-1 {
  padding-right: var(--spacing-one);
  padding-left: var(--spacing-one);
}

.px-2 {
  padding-right: var(--spacing-two);
  padding-left: var(--spacing-two);
}

.py-1 {
  padding-top: var(--spacing-one);
  padding-bottom: var(--spacing-one);
}

.py-2 {
  padding-top: var(--spacing-two);
  padding-bottom: var(--spacing-two);
}

.card {
  width: 5%;
  text-align: center;
}

.success .card {
  text-align: left;
}

.card-title {
  text-decoration: underline;
}

.success .card-title {
  text-decoration: none;
  font-weight: bold;
}

.card-text {
  font-weight: 500;
}

.accordion-body {
  text-align: left;
}

.accordion-header {
  height: 40px;
}

.img-thumbnail {
  width: auto;
  height: 300px;
  margin-bottom: 50px;
  cursor: pointer;
}

.modalBackdrop {
  background: rgba(0, 0, 0, .65);
  bottom: 0;
  left: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
}

.modalContainer {
  background: var(--darker);
  border-radius: 5px;
  max-width: 100%;
  margin: 50px auto;
  padding: 15px;
  width: 960px;
}

.modalTitle {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 25px;
}

.pdf-document {
  width: 100%;
  max-width: 1000px;
  height: auto;
  margin: 0 auto;
}

button {
  background: var(--primary);
  border: 0;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
  padding: 10px 15px;
}

a {
  border-radius: 5px;
  color: var(--primary);
  font-weight: 600;
  padding: 2px 4px;
  margin-left: -2px;
  margin-right: -2px;
}

.navActive {
  color: var(--primary);
}

#navbar {
  background-color: lightgrey;
  font-size: 20px;
  color: var(--darker);
  
}

@media only screen and (min-width: 800PX) {
  body {
    font-size: 12px;
  }

  main {
    margin: 0 auto;
    max-width: 85%;
    padding: 10px 10px;
  }
}

@media only screen and (min-width: 900PX) {
  body {
    font-size: 16px;
  }

  main {
    margin: 0 auto;
    max-width: 90%;
    padding: 10px 10px;
  }
}

@media only screen and (max-width: 400PX) {
  .react-pdf__Page__canvas {
    margin: 0 auto;
    width: 80% !important;
    height: 100% !important;
  }
}

@media (max-width: 576px) {
  .custom-popover {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width: 577px) and (max-width: 992px) {
  .custom-popover {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media (min-width: 993px) {
  .custom-popover {
    width: 800px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
